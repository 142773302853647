import * as React from "react";
import {useEffect, useState, useRef} from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import SolaiNft from "../components/solai-nft";
import ShowNewClaimedNfts from "../components/show-newclaimed-nfts";
import Countdown from 'react-countdown';
import {Link, navigate} from "gatsby";
import Modal from 'react-modal';

import logo_k011 from "../images/k011_logo_black.svg";
import logo_tensor from "../images/logo_tensor.svg";
import logo_solana from "../images/logo_solana_color.svg";
import logo_dreamcult from "../images/logo_dreamcult.svg";
import BannerImage from "../images/banner_tinyrainboots.png";
import loadingGif from "../images/loading.gif";
import close_modal from "../images/close_modal.svg"
import { getCollectionByOwner, mint, finalize, claim } from '../api/api';

const Tinyrainboot: React.FC = () => {

    //26th Sep at 7pm CET
    //const [dropDate, setDropDate] = useState(1695747600000);
    //Sun Sep 01 2024 00:00:00 GMT+0200 
    const [dropDate, setDropDate] = useState(Date.now());
    const [mintEndDate, setMintEndate] = useState(Date.now()+86400000);
    const [totalDrops, setTotalDrops] = useState(0);
    //const [remainingPercentage, setRemainingPercentage] = useState(0);
    const [progress, setProgress] = useState(0);

    const [mintedData, setMintedData] = useState({});

    const [isLive, setIsLive] = useState(true);
    const [isSoldOut, setIsSoldOut] = useState(false);
    const namesList = [
        'awakening',
        'gathering strength',
        'stepping into the beyond',
        'now',
        'into the unknown',
        'i can’t remember',
        'where i came from',
        'where i was going',
        'what i had planned',
        'who i was then',
        'who i wanted to be',
        'loss',
        'the only sensation',
        'squeezing my heart',
        'into total oblivion',
        'collapsing',
        'a clean slate',
        'vanished',
        'the weight of what i have forgotten',
        'immense',
        'heavy like a cloud',
        'lingering in my vision',
        'echoes',
        'confusion and haze',
        'disoriented',
        'darkness within',
        'grasping at light',
        'reaching out',
        'futile attempts to understand',
        'the meaning of all of this',
        'bewilderment',
        'what brought me here',
        'what i must learn',
        'obfuscated',
        'in this place',
        'i hear no other breath',
        'only silence',
        'i feel no other life',
        'no life but mine',
        'forsaken',
        'the air is hollow',
        'surreal',
        'expanding from my center',
        'remnants of where i’d gone before',
        'traces',
        'remainders of memories',
        'incomplete',
        'chipped and broken',
        'hardly worth a glance',
        'scattering',
        'destroyed',
        'dispersed to the corners of the beyond',
        'my body carries me',
        'heavy',
        'forward i walk',
        'ceaseless',
        'treading water and sea',
        'without sensation',
        'steps make hardly a sound',
        'forgotten and lost',
        'moving ever onward',
        'the bravest choice',
        'forward',
        'the only choice',
        'in barren lands',
        'unmapped places',
        'i carry on',
        'out of necessity',
        'charting a lost corner',
        'inside my mind',
        'outside my reality',
        'beyond everything i know',
        'within everything i am',
        'wondering',
        'tracing contours',
        'defining edges',
        'lines drawn in sand',
        'washed away again',
        'pathways of stone',
        'bridges of ice',
        'mapping the dream',
        'a confusing place',
        'elusive and fragmented',
        'chaos',
        'the hero continues',
        'a ceaseless journey',
        'through trials and tribulations',
        'inner strength',
        'the ultimate knowing',
        'curiosity',
        'propelled by questions',
        'a desire to understand',
        'what am i?',
        'crevices and shadows',
        'contrast',
        'untouched by light',
        'dark places',
        'where no one goes',
        'out of fear',
        'disquiet',
        'mistrust of the unknown',
        'who looks outside dreams',
        'who looks inside awakes',
        'so it goes',
        'exploring the mind',
        'vanquished',
        'topology of dreams',
        'unspeakably fragile',
        'strong beyond measure',
        'nothing and everything',
        'duplicitous',
        'the human condition',
        'growth and decay',
        'pleasant destruction',
        'vast eternity',
        'the dreamscape',
        'fragmented and whole',
        'it all fell apart',
        'strange undulations of ice',
        'unusual formations',
        'stillness',
        'i remember this',
        'sinew and synapse',
        'chaos and order',
        'continuing',
        'confusion and symmetry',
        'imitation',
        'perfect and broken',
        'a place forgotten',
        'suppressed',
        'a place remembered',
        'a hall of mirrors',
        'illusion',
        'spirals of memories',
        'haunted thoughts',
        'fractured bones',
        'painful and pitiful',
        'broken support',
        'carried by illusions',
        'within strange echoes',
        'remnants',
        'what i used to be',
        'nothing more than this',
        'alone',
        'it is as real as it is not',
        'all falls together',
        'my mind breaks apart',
        'shattered',
        'illusions of memory',
        'trapped',
        'mere illusions',
        'as real as i say',
        'ephemeral as dreams',
        'in transit',
        'destination unknown',
        'what i desire',
        'sleep',
        'what i fear',
        'the truth',
        'there is no truth',
        'hope is a broken promise',
        'i tell myself this',
        'desire is the devil',
        'i am merely a ghost',
        'haunted',
        'reaching for something real',
        'caressed by nightmares',
        'worry',
        'plagued by anxieties',
        'deserving of nothing',
        'pining for clarity',
        'yearning',
        'chaos reigns',
        'convoluted',
        'wandering endless horizons',
        'still i am here',
        'lost but found',
        'dreaming wide awake',
        'in perpetuity',
        'chasing shadows',
        'lucid dreaming',
        'a lost cause',
        'dashed hopes',
        'anomaly',
        'split perception',
        'hallucination',
        'figment of imagination',
        'where the clock does not tick',
        'unvarying',
        'without distraction',
        'time to think',
        'contemplating',
        'undefined meaning',
        'murky',
        'the abyss',
        'reflections of a troubled soul',
        'in purgatory',
        'recollection and familiarity',
        'engravings worn by time',
        'fading',
        'it’s not quite there',
        'lethologica',
        'forgetting the remembered',
        'footsteps in sand',
        'the cartography of a dream',
        'whispers',
        'exploring the unknown',
        'a deep breath of ice',
        'desert of my soul',
        'where i did not dare to go',
        'the grief i always carry',
        'unknowing',
        'stones on my back',
        'my own guide',
        'confusion',
        'have i lost myself?',
        'too many questions',
        'unbounded',
        'little pieces floating away',
        'unburying myself',
        'excavation',
        'dimensions of being',
        'layered core',
        'a test',
        'anthropology of the self',
        'conundrum',
        'twisted imaginings',
        'tender and tortured',
        'exquisite',
        'crown of ice',
        'a heavy thing',
        'sharp',
        'thinner and lighter',
        'what i owe to myself',
        'frozen in forever',
        'existence, the lost cause',
        'an overwhelming urge',
        'wings of the devil',
        'a secret lock i lost the key for',
        'oil slick',
        'a bad omen',
        'luck of the damned',
        'gloating',
        'lost the fight',
        'time’s biggest mistake',
        'i stopped believing',
        'fragility',
        'twisted hands of the old witch',
        'what i named that place',
        'a study in letting go',
        'traffic on the highway to hell',
        'a momentary panic',
        'obsolete',
        'casual endings',
        'let me begin again',
        'i don’t know where i am',
        'there must be an answer somewhere',
        'in a mess of chaos',
        'blue',
        'trembling terror',
        'they promised me something else',
        'coming to terms with the unconscious',
        'unforgivable',
        'missing a home that never was',
        'dreaming',
        'outlines of figures',
        'just in my mind',
        'i am alone here',
        'just as it has always been',
        'no one to blame',
        'tendrils of comprehension',
        'growing',
        'out of sight',
        'without mind',
        'lackluster',
        'perceptions of truth',
        'the mind’s eye',
        'my own muse',
        'longing',
        'desperate connection',
        'no echo, no answer',
        'a wall of silence',
        'i am coming to terms with this',
        'in insanity there is truth',
        'unfiltered',
        'skin as armor',
        'delicate membrane',
        'vulnerable',
        'all i am is this',
        'no trial is eternal',
        'life’s greatest quest',
        'to step beyond',
        'more than i ever thought i could be',
        'getting stuck is easy',
        'deciding to change',
        'an honorable pursuit',
        'endeavors of the soul',
        'body unweildy ',
        'the burrow',
        'alive and unliving',
        'it was always there',
        'skin turned to marble',
        'forgot how to speak',
        'calcified self',
        'gone ',
        'pacing the cage',
        'aware i was dreaming',
        'stolen from another time',
        'parallel life',
        'just a paradox',
        'a stroke of brilliance',
        'a moment of chaos',
        'the reality of untruth',
        'flipped',
        'tender steps',
        'eyes in the dark',
        'i knew what it meant',
        'uncalculated',
        'i looked inside my dreams',
        'faded away',
        'what i saw there',
        'no sense',
        'metaphors for deviations',
        'unseeing',
        'the snowglobe inside',
        'at ease and attention',
        'a waste of good space',
        'composed of mirrors',
        "it wasn't supposed to be like that",
        'poetry',
        'full of agony and longing ',
        'memories from a time before',
        'myopia',
        'the soft embrace',
    ];
    // set to false when soldout
    const [showButton, setShowButton] = useState(true);
    const collectionOwner = "3PPNSoZxnz9biNpQqw5EhztVB8EP8zhC75URpSeDCEbC";
    const collectionName = "The Contours of a Dream";
    const collectionImageUrl = "https://tinyrainboot.0kai-cdn.com/image/3/#token_id/1200/800.png";
    const collectionUrl = "/tinyrainboot/";
    const collectionRandomId = Math.floor(Math.random() * 100000);
    const [collection, setCollection] = useState(null);

    useEffect(() => {
        if (Date.now() >= dropDate) {
            setIsLive(true);
        }
       
    }, []);

    useEffect(() => {
        const fetchCollection = async () => {
            try {
                const collectionData = await getCollectionByOwner(collectionOwner);
                const maxSupply = parseInt(collectionData.collection.maxSupply, 16);
                const decimalTimestampStartDate = parseInt(collectionData.collection.saleStartTime, 16) * 1000;                
                const decimalTimestampEndDate = parseInt(collectionData.collection.saleEndTime, 16) * 1000;
                const mintCount = parseInt(collectionData.collection.mintCount, 16);
                if (mintCount == maxSupply) {
                    setIsSoldOut(true);
                }
                setCollection(collectionData.collection);
                setTotalDrops(maxSupply);
                console.log(decimalTimestampEndDate);
                setMintEndate(decimalTimestampEndDate);
                console.log(decimalTimestampStartDate);
                setDropDate(decimalTimestampStartDate);
                console.log(Date.now() );
                setProgress(mintCount);
            } catch (error) {
                console.error('Error fetching collection:', error);
            }
        };

        fetchCollection();

    }, [mintedData]);

    const countdownRenderer = ({days, hours, minutes, seconds, completed}) => {
        if (completed) {
           // setIsLive(true);
            if (showButton) {
                return <SolaiNft claimNftCallback={claimNftCountCallback} collectionOwner={collectionOwner} collectionId={collectionRandomId} collection={collection} collectionName={collectionName} collectionImageUrl={collectionImageUrl} />
            } else {
                return <button className='button black mint'><img alt="Loading" className="loading" src={loadingGif}/>
                </button>
            }

        } else {
            return <div className='button countdown'>
                <div>Live
                    in <span>{days} days</span><span>{hours}h</span>:<span>{minutes}m</span>:<span>{seconds}s</span>
                </div>
            </div>;
        }
    };

    const claimNftCountCallback = (mintedDataCallBack) => {
        setProgress((progress + 1));
        console.log(["Set progress", progress]);
        setMintedData(mintedDataCallBack);
    }

    const remainingPercentage = () =>{
       return ((progress / totalDrops) * 100).toFixed(2);
    }
    /* MODAL */
    const customStyles = {
        content: {top: '30%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', background: '#FFFFFF', zIndex: '999'},
        overlay: {zIndex: 1000, backgroundColor: 'rgba(0, 0, 0, 0.75)'}
    };

    Modal.setAppElement('#___gatsby');

    const [modalIsOpen, setModalIsOpen] = useState(true);

    function openModal() {
        setModalIsOpen(true);
    }

    function afterOpenModal() {
    }

    function closeModal() {
        setModalIsOpen(false);
        navigate(`/tinyrainboot/${mintedData.id}`);
    }

    function closeModalBefore() {
        setModalIsOpen(false);
    }

    return (
        <Layout>
            <div className='container clearfix'>
                <section className='section-banner populus' style={{backgroundImage: `url(${BannerImage})`}}>
                    <div className='container clearfix flex'></div>
                </section>
            </div>
            <section className='section-title collection populus'>
                <Seo title="Playground - The Contours of a Dream by Tinyrainboot"/>
                <div className='container clearfix flex'>
                    <div className='col50 left info'>
                        <div className='clearfix tags'>
                            <Link to='/collections/' className='button black left'>Collection</Link>
                            <a className='button black left'> {progress?progress:"0"} minted</a>
                        </div>
                        <h1>The Contours of a Dream<br/><span>by Tinyrainboot</span></h1>
                        <p className='big'>I awaken in a world that isn’t mine. Eerie and desolate, the weight of a loss I can’t quite remember washes over me. I thought I knew what time was, but it has lost its meaning in a dawn that stretches its fingers toward eternity. The air is different here: sharper, thinner, lighter. Can you imagine the sound of a planet where you are the only one breathing? I do not know if this is future or past, whether this place is forgotten or still unknown. My unspeakably fragile vessel of sinew and synapse is all that carries me forward. Have I been here before? Or am I simply tracing the contours of a dream?</p>
                        <p className='big'>“Your visions will become clear only when you can look into your own heart. Who looks outside, dreams; who looks inside, awakes.”<br/> ― C.G. Jung</p>
                    </div>  
                    <div className='col50 left mint-actions'>
                        <div className='mint-box'>
                            <div className='solana-icon'>
                                <h4>Secured By</h4>
                                <a href="https://solana.com/" target="_blank"><img className='partner-logo solana' src={logo_solana}/></a>
                            </div>
                            <h2><span>price</span>{collection?(collection.price).toFixed(2):"X"} SOL</h2>
   {/*                          <div>
                                {collection ? (
                                    <div>
                                        <h1>{collection.name}</h1>
                                        <p>Ref: {collection.reference}</p>
                                        <p>Symbol: {collection.symbol}</p>
                                        <p>Sale Start Time: {collection.saleStartTime}</p>
                                        <p>Sale Start Time: {collection.saleEndTime}</p>
                                        <p>Max Supply: {collection.maxSupply}</p>
                                        <p>Total Supply: {parseInt(collection.totalSupply)}</p>
                                        <p>Price: {collection.price}</p>
                                        <p>Stable ID: {collection.stableId}</p>
                                        <p>Mint Count: {collection.mintCount}</p>
                                        <p>Remaining Percentage: {remainingPercentage}%</p>
                                        <p>image : <a href={collection.url}>{collection.url}</a></p>
                                        {isSoldOut && <p>This collection is sold out!</p>}
                                    </div>
                                ) : (
                                    <div>No collection data found.</div>
                                )}
                            </div> */}
                            <p>{remainingPercentage()}% minted ({progress}/{totalDrops})</p>
                            <div className='progress-bar'>
                                <div className='progress' style={{width: `${remainingPercentage()}%`}}></div>
                            </div>
                            {
                                mintEndDate > Date.now() ?
                                    (
                                        isSoldOut
                                            ? (<button className='button black mint'>SOLDOUT</button>)
                                            : (<Countdown date={dropDate} renderer={countdownRenderer} />)
                                    )
                                    : (
                                        <button className='button black mint'>Collection is now closed</button>
                                    )
                            }
                        </div>                        
                    </div>
                </div>
                 
                <ShowNewClaimedNfts collectionOwner={collectionOwner} collectionImageUrl={collectionImageUrl} collectionName={collectionName} collectionUrl={collectionUrl} collectionListNames={namesList} />
            
            </section>
            
            {mintedData.address &&
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModalBefore}
                    style={customStyles}
                    portalClassName="minting-modal"
                    contentLabel="New Mint Modal"
                >
                    <div id="minting">
                        <a className='close-modal' onClick={closeModalBefore}><img src={close_modal}/></a>
                        <div className="minting-step-info">
                            <h3>Congratulations!</h3>
                            <p>You minted:</p><p className='big'>{mintedData.name}</p>
                            <div className='minted-image'><img src={mintedData.address} alt='Image rendered...' /></div>
                            <button className='button black close' onClick={closeModal}>See your artwork!</button>
                        </div>
                    </div>
                </Modal>
            }
        </Layout>
    );
};

export default Tinyrainboot;